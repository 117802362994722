/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilingStatusEnum } from './FilingStatusEnum';
import {
    FilingStatusEnumFromJSON,
    FilingStatusEnumFromJSONTyped,
    FilingStatusEnumToJSON,
} from './FilingStatusEnum';
import type { PaymentStatusEnum } from './PaymentStatusEnum';
import {
    PaymentStatusEnumFromJSON,
    PaymentStatusEnumFromJSONTyped,
    PaymentStatusEnumToJSON,
} from './PaymentStatusEnum';
import type { ReminderStatusEnum } from './ReminderStatusEnum';
import {
    ReminderStatusEnumFromJSON,
    ReminderStatusEnumFromJSONTyped,
    ReminderStatusEnumToJSON,
} from './ReminderStatusEnum';
import type { FilingTypeEd2Enum } from './FilingTypeEd2Enum';
import {
    FilingTypeEd2EnumFromJSON,
    FilingTypeEd2EnumFromJSONTyped,
    FilingTypeEd2EnumToJSON,
} from './FilingTypeEd2Enum';
import type { MailStateEnum } from './MailStateEnum';
import {
    MailStateEnumFromJSON,
    MailStateEnumFromJSONTyped,
    MailStateEnumToJSON,
} from './MailStateEnum';
import type { FloridaCorporationStatus } from './FloridaCorporationStatus';
import {
    FloridaCorporationStatusFromJSON,
    FloridaCorporationStatusFromJSONTyped,
    FloridaCorporationStatusToJSON,
} from './FloridaCorporationStatus';
import type { FeinStatusEnum } from './FeinStatusEnum';
import {
    FeinStatusEnumFromJSON,
    FeinStatusEnumFromJSONTyped,
    FeinStatusEnumToJSON,
} from './FeinStatusEnum';
import type { OfficerTypeEnum } from './OfficerTypeEnum';
import {
    OfficerTypeEnumFromJSON,
    OfficerTypeEnumFromJSONTyped,
    OfficerTypeEnumToJSON,
} from './OfficerTypeEnum';
import type { ReportNameEnum } from './ReportNameEnum';
import {
    ReportNameEnumFromJSON,
    ReportNameEnumFromJSONTyped,
    ReportNameEnumToJSON,
} from './ReportNameEnum';
import type { JurisdictionEnum } from './JurisdictionEnum';
import {
    JurisdictionEnumFromJSON,
    JurisdictionEnumFromJSONTyped,
    JurisdictionEnumToJSON,
} from './JurisdictionEnum';
import type { Officer } from './Officer';
import {
    OfficerFromJSON,
    OfficerFromJSONTyped,
    OfficerToJSON,
} from './Officer';

/**
 * 
 * @export
 * @interface AnnualReport
 */
export interface AnnualReport {
    /**
     * 
     * @type {number}
     * @memberof AnnualReport
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    readonly filingStatusDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    readonly jurisdictionDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    readonly paymentStatusDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    readonly reportNameDisplay: string;
    /**
     * 
     * @type {Array<Officer>}
     * @memberof AnnualReport
     */
    officers: Array<Officer>;
    /**
     * 
     * @type {number}
     * @memberof AnnualReport
     */
    signingOfficer: number;
    /**
     * 
     * @type {Date}
     * @memberof AnnualReport
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof AnnualReport
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {ReportNameEnum}
     * @memberof AnnualReport
     */
    reportName: ReportNameEnum;
    /**
     * 
     * @type {JurisdictionEnum}
     * @memberof AnnualReport
     */
    jurisdiction: JurisdictionEnum;
    /**
     * 
     * @type {FilingStatusEnum}
     * @memberof AnnualReport
     */
    filingStatus: FilingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    paymentType?: string;
    /**
     * Payment status
     * 
     * * `unpaid` - Unpaid
     * * `pending` - Pending
     * * `paid` - Paid
     * @type {PaymentStatusEnum}
     * @memberof AnnualReport
     */
    paymentStatus?: PaymentStatusEnum;
    /**
     * Reminder status
     * 
     * * `do_not_remind` - Do Not Remind
     * * `queued_for_reminder` - Queued for Reminder
     * * `sent_1d_reminder` - Sent 1 Day Reminder
     * * `sent_7d_reminder` - Sent 7 Day Reminder
     * * `sent_14d_reminder` - Sent 14 Day Reminder
     * * `sent_30d_reminder` - Sent 30 Day Reminder
     * * `next_reminder_phase` - Next Reminder Phase
     * * `new_filing` - New Filing Alert
     * * `boi_needed` - BOI Data Needed
     * * `annual_to_boi_remind` - Annual to BOI Reminder
     * * `boi_data_series` - BOI Data Series
     * * `error` - Error
     * @type {ReminderStatusEnum}
     * @memberof AnnualReport
     */
    reminderStatus?: ReminderStatusEnum;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof AnnualReport
     */
    reminder1dSent?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof AnnualReport
     */
    reminder7dSent?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof AnnualReport
     */
    reminder14dSent?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof AnnualReport
     */
    reminder30dSent?: Date | null;
    /**
     * The date and time the filing was received by governing agency.
     * @type {Date}
     * @memberof AnnualReport
     */
    receivedTimestamp?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof AnnualReport
     */
    filedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof AnnualReport
     */
    approvedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof AnnualReport
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    filingNote?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnualReport
     */
    percentComplete?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    emailNotificationStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    emailReminderStatus?: string;
    /**
     * The corporate document number.
     * @type {string}
     * @memberof AnnualReport
     */
    documentNumber: string;
    /**
     * The corporate name.
     * @type {string}
     * @memberof AnnualReport
     */
    corporationName: string;
    /**
     * Please select whether your business is Active or Inactive.
     * 
     * * `A` - Active
     * * `I` - Inactive
     * @type {FloridaCorporationStatus}
     * @memberof AnnualReport
     */
    status: FloridaCorporationStatus;
    /**
     * Type of filing.
     * 
     * * `DOMP` - Florida For Profit Corporation
     * * `DOMNP` - Florida Non-Profit Corporation
     * * `FORP` - Foreign Profit Corporation
     * * `FORNP` - Foreign Non-Profit Corporation
     * * `DOMLP` - Florida Limited Partnership
     * * `FORLP` - Foreign Limited Partnership
     * * `FLAL` - Florida Limited Liability Company
     * * `FORL` - Foreign Limited Liability Company
     * * `NPREG` - Non-Profit, Registration
     * * `MISC` - Miscellaneous
     * * `NR` - Name Reservation
     * * `TRUST` - Declaration of Trust
     * * `AGENT` - Designation of Registered Agent
     * * `RF` - Rejection Filing
     * * `TM` - Trademark
     * @type {FilingTypeEd2Enum}
     * @memberof AnnualReport
     */
    filingType: FilingTypeEd2Enum;
    /**
     * The full address of the business.
     * @type {string}
     * @memberof AnnualReport
     */
    businessAddress?: string;
    /**
     * Enter the street address of the business.
     * @type {string}
     * @memberof AnnualReport
     */
    businessAddress1: string;
    /**
     * Additional address information (e.g., suite, floor). Optional.
     * @type {string}
     * @memberof AnnualReport
     */
    businessAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    businessCity: string;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof AnnualReport
     */
    businessState: MailStateEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    businessZip: string;
    /**
     * Enter the 2-letter country code (e.g., US for United States).
     * @type {string}
     * @memberof AnnualReport
     */
    businessCountry?: string;
    /**
     * Enter the street address for mail correspondence.
     * @type {string}
     * @memberof AnnualReport
     */
    mailAddress1: string;
    /**
     * Additional address information (e.g., suite, floor). Optional.
     * @type {string}
     * @memberof AnnualReport
     */
    mailAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    mailCity: string;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof AnnualReport
     */
    mailState: MailStateEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    mailZip: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    mailCountry: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    fileDate: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    feiNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    moreThanSixOfficersFlag?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    lastTransactionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    filingState?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    registeredAgentName: string;
    /**
     * 
     * @type {OfficerTypeEnum}
     * @memberof AnnualReport
     */
    registeredAgentType: OfficerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    registeredAgentAddress1: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    registeredAgentAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    registeredAgentCity: string;
    /**
     * 
     * @type {MailStateEnum}
     * @memberof AnnualReport
     */
    registeredAgentState: MailStateEnum;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    registeredAgentZip: string;
    /**
     * Email address of primary contact
     * @type {string}
     * @memberof AnnualReport
     */
    emailAddress?: string | null;
    /**
     * This is a UUID generated from the users email.         It will be populated automatically on save.
     * @type {string}
     * @memberof AnnualReport
     */
    uuidFromEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    readonly cleanedContactName: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualReport
     */
    readonly cleanedContactLastName: string | null;
    /**
     * Is the FEI/EIN included with the filing?
     * 
     * * `I` - Included
     * * `A` - Applied For
     * * `NA` - Not Applicable
     * @type {FeinStatusEnum}
     * @memberof AnnualReport
     */
    feinStatus?: FeinStatusEnum;
    /**
     * What did they file their 2024 Annual Report?
     * @type {string}
     * @memberof AnnualReport
     */
    annualReport2024?: string | null;
    /**
     * Please type your name.
     * @type {string}
     * @memberof AnnualReport
     */
    signingOfficerSignature?: string;
    /**
     * Please type your name.
     * @type {string}
     * @memberof AnnualReport
     */
    signingOfficerTitle: string;
    /**
     * I am authorized to sign this report.
     * @type {boolean}
     * @memberof AnnualReport
     */
    signingOfficerAuthorization?: boolean;
    /**
     * Email address of primary contact
     * @type {string}
     * @memberof AnnualReport
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualReport
     */
    team: number;
}



/**
 * Check if a given object implements the AnnualReport interface.
 */
export function instanceOfAnnualReport(value: object): value is AnnualReport {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('filingStatusDisplay' in value) || value['filingStatusDisplay'] === undefined) return false;
    if (!('jurisdictionDisplay' in value) || value['jurisdictionDisplay'] === undefined) return false;
    if (!('paymentStatusDisplay' in value) || value['paymentStatusDisplay'] === undefined) return false;
    if (!('reportNameDisplay' in value) || value['reportNameDisplay'] === undefined) return false;
    if (!('officers' in value) || value['officers'] === undefined) return false;
    if (!('signingOfficer' in value) || value['signingOfficer'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('reportName' in value) || value['reportName'] === undefined) return false;
    if (!('jurisdiction' in value) || value['jurisdiction'] === undefined) return false;
    if (!('filingStatus' in value) || value['filingStatus'] === undefined) return false;
    if (!('documentNumber' in value) || value['documentNumber'] === undefined) return false;
    if (!('corporationName' in value) || value['corporationName'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('filingType' in value) || value['filingType'] === undefined) return false;
    if (!('businessAddress1' in value) || value['businessAddress1'] === undefined) return false;
    if (!('businessCity' in value) || value['businessCity'] === undefined) return false;
    if (!('businessState' in value) || value['businessState'] === undefined) return false;
    if (!('businessZip' in value) || value['businessZip'] === undefined) return false;
    if (!('mailAddress1' in value) || value['mailAddress1'] === undefined) return false;
    if (!('mailCity' in value) || value['mailCity'] === undefined) return false;
    if (!('mailState' in value) || value['mailState'] === undefined) return false;
    if (!('mailZip' in value) || value['mailZip'] === undefined) return false;
    if (!('mailCountry' in value) || value['mailCountry'] === undefined) return false;
    if (!('fileDate' in value) || value['fileDate'] === undefined) return false;
    if (!('registeredAgentName' in value) || value['registeredAgentName'] === undefined) return false;
    if (!('registeredAgentType' in value) || value['registeredAgentType'] === undefined) return false;
    if (!('registeredAgentAddress1' in value) || value['registeredAgentAddress1'] === undefined) return false;
    if (!('registeredAgentCity' in value) || value['registeredAgentCity'] === undefined) return false;
    if (!('registeredAgentState' in value) || value['registeredAgentState'] === undefined) return false;
    if (!('registeredAgentZip' in value) || value['registeredAgentZip'] === undefined) return false;
    if (!('cleanedContactName' in value) || value['cleanedContactName'] === undefined) return false;
    if (!('cleanedContactLastName' in value) || value['cleanedContactLastName'] === undefined) return false;
    if (!('signingOfficerTitle' in value) || value['signingOfficerTitle'] === undefined) return false;
    if (!('team' in value) || value['team'] === undefined) return false;
    return true;
}

export function AnnualReportFromJSON(json: any): AnnualReport {
    return AnnualReportFromJSONTyped(json, false);
}

export function AnnualReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnualReport {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filingStatusDisplay': json['filing_status_display'],
        'jurisdictionDisplay': json['jurisdiction_display'],
        'paymentStatusDisplay': json['payment_status_display'],
        'reportNameDisplay': json['report_name_display'],
        'officers': ((json['officers'] as Array<any>).map(OfficerFromJSON)),
        'signingOfficer': json['signing_officer'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'reportName': ReportNameEnumFromJSON(json['report_name']),
        'jurisdiction': JurisdictionEnumFromJSON(json['jurisdiction']),
        'filingStatus': FilingStatusEnumFromJSON(json['filing_status']),
        'paymentType': json['payment_type'] == null ? undefined : json['payment_type'],
        'paymentStatus': json['payment_status'] == null ? undefined : PaymentStatusEnumFromJSON(json['payment_status']),
        'reminderStatus': json['reminder_status'] == null ? undefined : ReminderStatusEnumFromJSON(json['reminder_status']),
        'reminder1dSent': json['reminder_1d_sent'] == null ? undefined : (new Date(json['reminder_1d_sent'])),
        'reminder7dSent': json['reminder_7d_sent'] == null ? undefined : (new Date(json['reminder_7d_sent'])),
        'reminder14dSent': json['reminder_14d_sent'] == null ? undefined : (new Date(json['reminder_14d_sent'])),
        'reminder30dSent': json['reminder_30d_sent'] == null ? undefined : (new Date(json['reminder_30d_sent'])),
        'receivedTimestamp': json['received_timestamp'] == null ? undefined : (new Date(json['received_timestamp'])),
        'filedDate': json['filed_date'] == null ? undefined : (new Date(json['filed_date'])),
        'approvedDate': json['approved_date'] == null ? undefined : (new Date(json['approved_date'])),
        'completedDate': json['completed_date'] == null ? undefined : (new Date(json['completed_date'])),
        'filingNote': json['filing_note'] == null ? undefined : json['filing_note'],
        'percentComplete': json['percent_complete'] == null ? undefined : json['percent_complete'],
        'emailNotificationStatus': json['email_notification_status'] == null ? undefined : json['email_notification_status'],
        'emailReminderStatus': json['email_reminder_status'] == null ? undefined : json['email_reminder_status'],
        'documentNumber': json['document_number'],
        'corporationName': json['corporation_name'],
        'status': FloridaCorporationStatusFromJSON(json['status']),
        'filingType': FilingTypeEd2EnumFromJSON(json['filing_type']),
        'businessAddress': json['business_address'] == null ? undefined : json['business_address'],
        'businessAddress1': json['business_address_1'],
        'businessAddress2': json['business_address_2'] == null ? undefined : json['business_address_2'],
        'businessCity': json['business_city'],
        'businessState': MailStateEnumFromJSON(json['business_state']),
        'businessZip': json['business_zip'],
        'businessCountry': json['business_country'] == null ? undefined : json['business_country'],
        'mailAddress1': json['mail_address_1'],
        'mailAddress2': json['mail_address_2'] == null ? undefined : json['mail_address_2'],
        'mailCity': json['mail_city'],
        'mailState': MailStateEnumFromJSON(json['mail_state']),
        'mailZip': json['mail_zip'],
        'mailCountry': json['mail_country'],
        'fileDate': json['file_date'],
        'feiNumber': json['fei_number'] == null ? undefined : json['fei_number'],
        'moreThanSixOfficersFlag': json['more_than_six_officers_flag'] == null ? undefined : json['more_than_six_officers_flag'],
        'lastTransactionDate': json['last_transaction_date'] == null ? undefined : json['last_transaction_date'],
        'filingState': json['filing_state'] == null ? undefined : json['filing_state'],
        'registeredAgentName': json['registered_agent_name'],
        'registeredAgentType': OfficerTypeEnumFromJSON(json['registered_agent_type']),
        'registeredAgentAddress1': json['registered_agent_address_1'],
        'registeredAgentAddress2': json['registered_agent_address_2'] == null ? undefined : json['registered_agent_address_2'],
        'registeredAgentCity': json['registered_agent_city'],
        'registeredAgentState': MailStateEnumFromJSON(json['registered_agent_state']),
        'registeredAgentZip': json['registered_agent_zip'],
        'emailAddress': json['email_address'] == null ? undefined : json['email_address'],
        'uuidFromEmail': json['uuid_from_email'] == null ? undefined : json['uuid_from_email'],
        'cleanedContactName': json['cleaned_contact_name'],
        'cleanedContactLastName': json['cleaned_contact_last_name'],
        'feinStatus': json['fein_status'] == null ? undefined : FeinStatusEnumFromJSON(json['fein_status']),
        'annualReport2024': json['annual_report_2024'] == null ? undefined : json['annual_report_2024'],
        'signingOfficerSignature': json['signing_officer_signature'] == null ? undefined : json['signing_officer_signature'],
        'signingOfficerTitle': json['signing_officer_title'],
        'signingOfficerAuthorization': json['signing_officer_authorization'] == null ? undefined : json['signing_officer_authorization'],
        'contactEmail': json['contact_email'] == null ? undefined : json['contact_email'],
        'team': json['team'],
    };
}

export function AnnualReportToJSON(value?: Omit<AnnualReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'created_at'|'updated_at'|'cleaned_contact_name'|'cleaned_contact_last_name'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'officers': ((value['officers'] as Array<any>).map(OfficerToJSON)),
        'signing_officer': value['signingOfficer'],
        'report_name': ReportNameEnumToJSON(value['reportName']),
        'jurisdiction': JurisdictionEnumToJSON(value['jurisdiction']),
        'filing_status': FilingStatusEnumToJSON(value['filingStatus']),
        'payment_type': value['paymentType'],
        'payment_status': PaymentStatusEnumToJSON(value['paymentStatus']),
        'reminder_status': ReminderStatusEnumToJSON(value['reminderStatus']),
        'reminder_1d_sent': value['reminder1dSent'] == null ? undefined : ((value['reminder1dSent'] as any).toISOString().substring(0,10)),
        'reminder_7d_sent': value['reminder7dSent'] == null ? undefined : ((value['reminder7dSent'] as any).toISOString().substring(0,10)),
        'reminder_14d_sent': value['reminder14dSent'] == null ? undefined : ((value['reminder14dSent'] as any).toISOString().substring(0,10)),
        'reminder_30d_sent': value['reminder30dSent'] == null ? undefined : ((value['reminder30dSent'] as any).toISOString().substring(0,10)),
        'received_timestamp': value['receivedTimestamp'] == null ? undefined : ((value['receivedTimestamp'] as any).toISOString()),
        'filed_date': value['filedDate'] == null ? undefined : ((value['filedDate'] as any).toISOString().substring(0,10)),
        'approved_date': value['approvedDate'] == null ? undefined : ((value['approvedDate'] as any).toISOString().substring(0,10)),
        'completed_date': value['completedDate'] == null ? undefined : ((value['completedDate'] as any).toISOString().substring(0,10)),
        'filing_note': value['filingNote'],
        'percent_complete': value['percentComplete'],
        'email_notification_status': value['emailNotificationStatus'],
        'email_reminder_status': value['emailReminderStatus'],
        'document_number': value['documentNumber'],
        'corporation_name': value['corporationName'],
        'status': FloridaCorporationStatusToJSON(value['status']),
        'filing_type': FilingTypeEd2EnumToJSON(value['filingType']),
        'business_address': value['businessAddress'],
        'business_address_1': value['businessAddress1'],
        'business_address_2': value['businessAddress2'],
        'business_city': value['businessCity'],
        'business_state': MailStateEnumToJSON(value['businessState']),
        'business_zip': value['businessZip'],
        'business_country': value['businessCountry'],
        'mail_address_1': value['mailAddress1'],
        'mail_address_2': value['mailAddress2'],
        'mail_city': value['mailCity'],
        'mail_state': MailStateEnumToJSON(value['mailState']),
        'mail_zip': value['mailZip'],
        'mail_country': value['mailCountry'],
        'file_date': value['fileDate'],
        'fei_number': value['feiNumber'],
        'more_than_six_officers_flag': value['moreThanSixOfficersFlag'],
        'last_transaction_date': value['lastTransactionDate'],
        'filing_state': value['filingState'],
        'registered_agent_name': value['registeredAgentName'],
        'registered_agent_type': OfficerTypeEnumToJSON(value['registeredAgentType']),
        'registered_agent_address_1': value['registeredAgentAddress1'],
        'registered_agent_address_2': value['registeredAgentAddress2'],
        'registered_agent_city': value['registeredAgentCity'],
        'registered_agent_state': MailStateEnumToJSON(value['registeredAgentState']),
        'registered_agent_zip': value['registeredAgentZip'],
        'email_address': value['emailAddress'],
        'uuid_from_email': value['uuidFromEmail'],
        'fein_status': FeinStatusEnumToJSON(value['feinStatus']),
        'annual_report_2024': value['annualReport2024'],
        'signing_officer_signature': value['signingOfficerSignature'],
        'signing_officer_title': value['signingOfficerTitle'],
        'signing_officer_authorization': value['signingOfficerAuthorization'],
        'contact_email': value['contactEmail'],
        'team': value['team'],
    };
}

