/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilingStatusEnum } from './FilingStatusEnum';
import {
    FilingStatusEnumFromJSON,
    FilingStatusEnumFromJSONTyped,
    FilingStatusEnumToJSON,
} from './FilingStatusEnum';
import type { PaymentStatusEnum } from './PaymentStatusEnum';
import {
    PaymentStatusEnumFromJSON,
    PaymentStatusEnumFromJSONTyped,
    PaymentStatusEnumToJSON,
} from './PaymentStatusEnum';
import type { ReminderStatusEnum } from './ReminderStatusEnum';
import {
    ReminderStatusEnumFromJSON,
    ReminderStatusEnumFromJSONTyped,
    ReminderStatusEnumToJSON,
} from './ReminderStatusEnum';
import type { TaxIdTypeEnum } from './TaxIdTypeEnum';
import {
    TaxIdTypeEnumFromJSON,
    TaxIdTypeEnumFromJSONTyped,
    TaxIdTypeEnumToJSON,
} from './TaxIdTypeEnum';
import type { CompanyApplicant } from './CompanyApplicant';
import {
    CompanyApplicantFromJSON,
    CompanyApplicantFromJSONTyped,
    CompanyApplicantToJSON,
} from './CompanyApplicant';
import type { TypeOfFilingEnum } from './TypeOfFilingEnum';
import {
    TypeOfFilingEnumFromJSON,
    TypeOfFilingEnumFromJSONTyped,
    TypeOfFilingEnumToJSON,
} from './TypeOfFilingEnum';
import type { BeneficialOwner } from './BeneficialOwner';
import {
    BeneficialOwnerFromJSON,
    BeneficialOwnerFromJSONTyped,
    BeneficialOwnerToJSON,
} from './BeneficialOwner';
import type { ReportNameEnum } from './ReportNameEnum';
import {
    ReportNameEnumFromJSON,
    ReportNameEnumFromJSONTyped,
    ReportNameEnumToJSON,
} from './ReportNameEnum';
import type { JurisdictionEnum } from './JurisdictionEnum';
import {
    JurisdictionEnumFromJSON,
    JurisdictionEnumFromJSONTyped,
    JurisdictionEnumToJSON,
} from './JurisdictionEnum';

/**
 * 
 * @export
 * @interface BeneficialOwnershipReport
 */
export interface BeneficialOwnershipReport {
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnershipReport
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    readonly filingStatusDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    readonly jurisdictionDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    readonly paymentStatusDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    readonly reportNameDisplay: string;
    /**
     * 
     * @type {Array<BeneficialOwner>}
     * @memberof BeneficialOwnershipReport
     */
    beneficialOwners: Array<BeneficialOwner>;
    /**
     * 
     * @type {Array<CompanyApplicant>}
     * @memberof BeneficialOwnershipReport
     */
    companyApplicants: Array<CompanyApplicant>;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    readonly typeOfFilingDisplay: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    readonly taxIdTypeDisplay: string;
    /**
     * 
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {ReportNameEnum}
     * @memberof BeneficialOwnershipReport
     */
    reportName: ReportNameEnum;
    /**
     * 
     * @type {JurisdictionEnum}
     * @memberof BeneficialOwnershipReport
     */
    jurisdiction: JurisdictionEnum;
    /**
     * 
     * @type {FilingStatusEnum}
     * @memberof BeneficialOwnershipReport
     */
    filingStatus: FilingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    paymentType?: string;
    /**
     * Payment status
     * 
     * * `unpaid` - Unpaid
     * * `pending` - Pending
     * * `paid` - Paid
     * @type {PaymentStatusEnum}
     * @memberof BeneficialOwnershipReport
     */
    paymentStatus?: PaymentStatusEnum;
    /**
     * Reminder status
     * 
     * * `do_not_remind` - Do Not Remind
     * * `queued_for_reminder` - Queued for Reminder
     * * `sent_1d_reminder` - Sent 1 Day Reminder
     * * `sent_7d_reminder` - Sent 7 Day Reminder
     * * `sent_14d_reminder` - Sent 14 Day Reminder
     * * `sent_30d_reminder` - Sent 30 Day Reminder
     * * `next_reminder_phase` - Next Reminder Phase
     * * `new_filing` - New Filing Alert
     * * `boi_needed` - BOI Data Needed
     * * `annual_to_boi_remind` - Annual to BOI Reminder
     * * `boi_data_series` - BOI Data Series
     * * `error` - Error
     * @type {ReminderStatusEnum}
     * @memberof BeneficialOwnershipReport
     */
    reminderStatus?: ReminderStatusEnum;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    reminder1dSent?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    reminder7dSent?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    reminder14dSent?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    reminder30dSent?: Date | null;
    /**
     * The date and time the filing was received by governing agency.
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    receivedTimestamp?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    filedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    approvedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    filingNote?: string;
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnershipReport
     */
    percentComplete?: number;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    emailNotificationStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    emailReminderStatus?: string;
    /**
     * 
     * @type {TypeOfFilingEnum}
     * @memberof BeneficialOwnershipReport
     */
    typeOfFiling?: TypeOfFilingEnum;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof BeneficialOwnershipReport
     */
    filingDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    reportingCompanyLegalName: string;
    /**
     * Alternate name (e.g. trade name, DBA)
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    alternateName?: string;
    /**
     * Check this box if the reporting company is a foreign pooled investment vehicle
     * @type {boolean}
     * @memberof BeneficialOwnershipReport
     */
    foreignPooledInvestmentVehicle?: boolean;
    /**
     * 
     * @type {TaxIdTypeEnum}
     * @memberof BeneficialOwnershipReport
     */
    taxIdType?: TaxIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    taxIdNumber?: string;
    /**
     * Country of issuance (if Foreign Tax ID Only
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    taxIdCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    countryOfFormation?: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    stateOfFormation?: string;
    /**
     * If applicable
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    tribalJurisdiction?: string;
    /**
     * If applicable
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    nameOfTribe?: string;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    businessAddress1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    businessAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    businessCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    businessState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    businessZip?: string | null;
    /**
     * Enter the 2-letter country code (e.g., US for United States).
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    businessCountry?: string | null;
    /**
     * Is this person an existing reporting company?
     * @type {boolean}
     * @memberof BeneficialOwnershipReport
     */
    existingReportingCompany?: boolean;
    /**
     * Email address of primary contact
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    contactEmail?: string | null;
    /**
     * First name of the person who submitted this report
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    submittedByFirstName?: string | null;
    /**
     * Last name of the person who submitted this report
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    submittedByLastName?: string | null;
    /**
     * Email of the person who submitted this report (may differ from contact_email)
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    submittedByEmail?: string | null;
    /**
     * Submission Tracking ID
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    submissionTrackingId?: string;
    /**
     * BOIR ID
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    boirId?: string | null;
    /**
     * A copy of the submission transcript
     * @type {string}
     * @memberof BeneficialOwnershipReport
     */
    submissionTranscript?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BeneficialOwnershipReport
     */
    readonly team: number;
}



/**
 * Check if a given object implements the BeneficialOwnershipReport interface.
 */
export function instanceOfBeneficialOwnershipReport(value: object): value is BeneficialOwnershipReport {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('filingStatusDisplay' in value) || value['filingStatusDisplay'] === undefined) return false;
    if (!('jurisdictionDisplay' in value) || value['jurisdictionDisplay'] === undefined) return false;
    if (!('paymentStatusDisplay' in value) || value['paymentStatusDisplay'] === undefined) return false;
    if (!('reportNameDisplay' in value) || value['reportNameDisplay'] === undefined) return false;
    if (!('beneficialOwners' in value) || value['beneficialOwners'] === undefined) return false;
    if (!('companyApplicants' in value) || value['companyApplicants'] === undefined) return false;
    if (!('typeOfFilingDisplay' in value) || value['typeOfFilingDisplay'] === undefined) return false;
    if (!('taxIdTypeDisplay' in value) || value['taxIdTypeDisplay'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('reportName' in value) || value['reportName'] === undefined) return false;
    if (!('jurisdiction' in value) || value['jurisdiction'] === undefined) return false;
    if (!('filingStatus' in value) || value['filingStatus'] === undefined) return false;
    if (!('reportingCompanyLegalName' in value) || value['reportingCompanyLegalName'] === undefined) return false;
    if (!('team' in value) || value['team'] === undefined) return false;
    return true;
}

export function BeneficialOwnershipReportFromJSON(json: any): BeneficialOwnershipReport {
    return BeneficialOwnershipReportFromJSONTyped(json, false);
}

export function BeneficialOwnershipReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BeneficialOwnershipReport {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filingStatusDisplay': json['filing_status_display'],
        'jurisdictionDisplay': json['jurisdiction_display'],
        'paymentStatusDisplay': json['payment_status_display'],
        'reportNameDisplay': json['report_name_display'],
        'beneficialOwners': ((json['beneficial_owners'] as Array<any>).map(BeneficialOwnerFromJSON)),
        'companyApplicants': ((json['company_applicants'] as Array<any>).map(CompanyApplicantFromJSON)),
        'typeOfFilingDisplay': json['type_of_filing_display'],
        'taxIdTypeDisplay': json['tax_id_type_display'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'reportName': ReportNameEnumFromJSON(json['report_name']),
        'jurisdiction': JurisdictionEnumFromJSON(json['jurisdiction']),
        'filingStatus': FilingStatusEnumFromJSON(json['filing_status']),
        'paymentType': json['payment_type'] == null ? undefined : json['payment_type'],
        'paymentStatus': json['payment_status'] == null ? undefined : PaymentStatusEnumFromJSON(json['payment_status']),
        'reminderStatus': json['reminder_status'] == null ? undefined : ReminderStatusEnumFromJSON(json['reminder_status']),
        'reminder1dSent': json['reminder_1d_sent'] == null ? undefined : (new Date(json['reminder_1d_sent'])),
        'reminder7dSent': json['reminder_7d_sent'] == null ? undefined : (new Date(json['reminder_7d_sent'])),
        'reminder14dSent': json['reminder_14d_sent'] == null ? undefined : (new Date(json['reminder_14d_sent'])),
        'reminder30dSent': json['reminder_30d_sent'] == null ? undefined : (new Date(json['reminder_30d_sent'])),
        'receivedTimestamp': json['received_timestamp'] == null ? undefined : (new Date(json['received_timestamp'])),
        'filedDate': json['filed_date'] == null ? undefined : (new Date(json['filed_date'])),
        'approvedDate': json['approved_date'] == null ? undefined : (new Date(json['approved_date'])),
        'completedDate': json['completed_date'] == null ? undefined : (new Date(json['completed_date'])),
        'filingNote': json['filing_note'] == null ? undefined : json['filing_note'],
        'percentComplete': json['percent_complete'] == null ? undefined : json['percent_complete'],
        'emailNotificationStatus': json['email_notification_status'] == null ? undefined : json['email_notification_status'],
        'emailReminderStatus': json['email_reminder_status'] == null ? undefined : json['email_reminder_status'],
        'typeOfFiling': json['type_of_filing'] == null ? undefined : TypeOfFilingEnumFromJSON(json['type_of_filing']),
        'filingDate': json['filing_date'] == null ? undefined : (new Date(json['filing_date'])),
        'reportingCompanyLegalName': json['reporting_company_legal_name'],
        'alternateName': json['alternate_name'] == null ? undefined : json['alternate_name'],
        'foreignPooledInvestmentVehicle': json['foreign_pooled_investment_vehicle'] == null ? undefined : json['foreign_pooled_investment_vehicle'],
        'taxIdType': json['tax_id_type'] == null ? undefined : TaxIdTypeEnumFromJSON(json['tax_id_type']),
        'taxIdNumber': json['tax_id_number'] == null ? undefined : json['tax_id_number'],
        'taxIdCountry': json['tax_id_country'] == null ? undefined : json['tax_id_country'],
        'countryOfFormation': json['country_of_formation'] == null ? undefined : json['country_of_formation'],
        'stateOfFormation': json['state_of_formation'] == null ? undefined : json['state_of_formation'],
        'tribalJurisdiction': json['tribal_jurisdiction'] == null ? undefined : json['tribal_jurisdiction'],
        'nameOfTribe': json['name_of_tribe'] == null ? undefined : json['name_of_tribe'],
        'businessAddress1': json['business_address_1'] == null ? undefined : json['business_address_1'],
        'businessAddress2': json['business_address_2'] == null ? undefined : json['business_address_2'],
        'businessCity': json['business_city'] == null ? undefined : json['business_city'],
        'businessState': json['business_state'] == null ? undefined : json['business_state'],
        'businessZip': json['business_zip'] == null ? undefined : json['business_zip'],
        'businessCountry': json['business_country'] == null ? undefined : json['business_country'],
        'existingReportingCompany': json['existing_reporting_company'] == null ? undefined : json['existing_reporting_company'],
        'contactEmail': json['contact_email'] == null ? undefined : json['contact_email'],
        'submittedByFirstName': json['submitted_by_first_name'] == null ? undefined : json['submitted_by_first_name'],
        'submittedByLastName': json['submitted_by_last_name'] == null ? undefined : json['submitted_by_last_name'],
        'submittedByEmail': json['submitted_by_email'] == null ? undefined : json['submitted_by_email'],
        'submissionTrackingId': json['submission_tracking_id'] == null ? undefined : json['submission_tracking_id'],
        'boirId': json['boir_id'] == null ? undefined : json['boir_id'],
        'submissionTranscript': json['submission_transcript'] == null ? undefined : json['submission_transcript'],
        'team': json['team'],
    };
}

export function BeneficialOwnershipReportToJSON(value?: Omit<BeneficialOwnershipReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'type_of_filing_display'|'tax_id_type_display'|'created_at'|'updated_at'|'team'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'beneficial_owners': ((value['beneficialOwners'] as Array<any>).map(BeneficialOwnerToJSON)),
        'company_applicants': ((value['companyApplicants'] as Array<any>).map(CompanyApplicantToJSON)),
        'report_name': ReportNameEnumToJSON(value['reportName']),
        'jurisdiction': JurisdictionEnumToJSON(value['jurisdiction']),
        'filing_status': FilingStatusEnumToJSON(value['filingStatus']),
        'payment_type': value['paymentType'],
        'payment_status': PaymentStatusEnumToJSON(value['paymentStatus']),
        'reminder_status': ReminderStatusEnumToJSON(value['reminderStatus']),
        'reminder_1d_sent': value['reminder1dSent'] == null ? undefined : ((value['reminder1dSent'] as any).toISOString().substring(0,10)),
        'reminder_7d_sent': value['reminder7dSent'] == null ? undefined : ((value['reminder7dSent'] as any).toISOString().substring(0,10)),
        'reminder_14d_sent': value['reminder14dSent'] == null ? undefined : ((value['reminder14dSent'] as any).toISOString().substring(0,10)),
        'reminder_30d_sent': value['reminder30dSent'] == null ? undefined : ((value['reminder30dSent'] as any).toISOString().substring(0,10)),
        'received_timestamp': value['receivedTimestamp'] == null ? undefined : ((value['receivedTimestamp'] as any).toISOString()),
        'filed_date': value['filedDate'] == null ? undefined : ((value['filedDate'] as any).toISOString().substring(0,10)),
        'approved_date': value['approvedDate'] == null ? undefined : ((value['approvedDate'] as any).toISOString().substring(0,10)),
        'completed_date': value['completedDate'] == null ? undefined : ((value['completedDate'] as any).toISOString().substring(0,10)),
        'filing_note': value['filingNote'],
        'percent_complete': value['percentComplete'],
        'email_notification_status': value['emailNotificationStatus'],
        'email_reminder_status': value['emailReminderStatus'],
        'type_of_filing': TypeOfFilingEnumToJSON(value['typeOfFiling']),
        'filing_date': value['filingDate'] == null ? undefined : ((value['filingDate'] as any).toISOString().substring(0,10)),
        'reporting_company_legal_name': value['reportingCompanyLegalName'],
        'alternate_name': value['alternateName'],
        'foreign_pooled_investment_vehicle': value['foreignPooledInvestmentVehicle'],
        'tax_id_type': TaxIdTypeEnumToJSON(value['taxIdType']),
        'tax_id_number': value['taxIdNumber'],
        'tax_id_country': value['taxIdCountry'],
        'country_of_formation': value['countryOfFormation'],
        'state_of_formation': value['stateOfFormation'],
        'tribal_jurisdiction': value['tribalJurisdiction'],
        'name_of_tribe': value['nameOfTribe'],
        'business_address_1': value['businessAddress1'],
        'business_address_2': value['businessAddress2'],
        'business_city': value['businessCity'],
        'business_state': value['businessState'],
        'business_zip': value['businessZip'],
        'business_country': value['businessCountry'],
        'existing_reporting_company': value['existingReportingCompany'],
        'contact_email': value['contactEmail'],
        'submitted_by_first_name': value['submittedByFirstName'],
        'submitted_by_last_name': value['submittedByLastName'],
        'submitted_by_email': value['submittedByEmail'],
        'submission_tracking_id': value['submissionTrackingId'],
        'boir_id': value['boirId'],
        'submission_transcript': value['submissionTranscript'],
    };
}

